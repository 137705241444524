import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Router } from '@angular/router';
import {AuthService} from "../services/auth.service";
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loginError = false;
  loginVazio = false;

  constructor(public auth: AuthService, public router: Router, private formBuilder: FormBuilder, private apiService: ApiService) {
      this.loginForm = this.formBuilder.group({
          usuario: ['', Validators.required],
          senha: ['', Validators.required],
      });
  }

  ngOnInit() {}

  login() {
      if (this.loginForm.valid) {
          this.apiService.login(this.loginForm.getRawValue()).subscribe((response: any) => {
              if (response.success == 0) {
                  this.loginError = true;
                  this.loginVazio = false;
              }
              sessionStorage.setItem('userdata', response.token);
              if (this.auth.isAuthenticated()) {
                  this.router.navigate(['main']);
              }
          });
      } else {
          this.loginVazio = true;
      }
  }

}
