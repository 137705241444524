import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TabelaComponent } from "./tabela.component";
import { ApiService } from "../services/api.service";
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";

import { InserirAnimalComponent } from "../inserir-animal/inserir-animal.component";
import { InserirPesagemComponent } from "../inserir-pesagem/inserir-pesagem.component";

import { ConfirmDialogModule } from "../confirm-dialog/confirm-dialog.module";




@NgModule({
  declarations: [TabelaComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatMenuModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatIconModule,
    ConfirmDialogModule
  ],
  exports: [
    TabelaComponent
  ],
  providers: [
    ApiService
  ],
  entryComponents: [
    InserirAnimalComponent,
    InserirPesagemComponent
  ]
})
export class TabelaModule { }
