import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { TabelaModule } from './tabela/tabela.module';
import { MainComponent } from './main/main.component';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { ApiService } from "./services/api.service";
import { InserirAnimalComponent } from './inserir-animal/inserir-animal.component';
import { InserirPesagemComponent } from './inserir-pesagem/inserir-pesagem.component';

import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatDatepickerModule } from "@angular/material/datepicker";

import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxMaskModule} from 'ngx-mask'
import { ToastrModule } from 'ngx-toastr';
import { MatCardModule, MatToolbarModule} from "@angular/material"






import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from '@angular/material-moment-adapter';

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';
import { LoginComponent } from './login/login.component';

import {AuthGuardService  as AuthGuard } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { NavbarComponent } from './navbar/navbar.component';


const appRoutes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'main', component: MainComponent, canActivate : [AuthGuard] },
];


@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    InserirAnimalComponent,
    InserirPesagemComponent,
    LoginComponent,
    NavbarComponent
  ],
  imports: [
    BrowserModule,
    TabelaModule,
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatPaginatorModule,
    MatMenuModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatCardModule,
    MatToolbarModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot()

  ],
  providers: [ApiService, AuthService, AuthGuard, { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
  {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
  },
  { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }],
  bootstrap: [AppComponent]
})
export class AppModule { }
