import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { AuthService } from "./auth.service";
import { ApiService } from "./api.service";
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthGuardService implements CanActivate {
    private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		public auth: AuthService,
		public router: Router,
		private apiService: ApiService
    ) {}

    get isLoggedIn() {
        return this.loggedIn.asObservable();
    }


	canActivate(): any {
		return this.apiService
			.checarAutenticacao(sessionStorage.getItem("userdata"))
			.toPromise()
			.then((response: any) => {
				if (response.success == 1) {
                    this.loggedIn.next(true);
					return true;
				} else {
                    this.loggedIn.next(false);
					return false;
				}
			});

    }

    logout() {
        this.loggedIn.next(false);
        sessionStorage.removeItem("userdata");
		this.router.navigate([""]);
    }
}
