import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from "../../environments/environment";
import { map } from 'rxjs/operators';
import formurlencoded from 'form-urlencoded';

@Injectable()
export class ApiService {
  private header = new HttpHeaders({
    'Content-type': 'application/x-www-form-urlencoded'
  })

  constructor(private http: HttpClient) { }

  /*   login(dados: any) {
      return this.http.post(`${environment.api_url}/view/Usuarios/VerificarUsuarioSenhaJSON`, `usuario=${dados.login}&senha=${dados.senha}`, { withCredentials: true, headers: { "Content-Type": "application/x-www-form-urlencoded" } }).pipe(map(response => response .json()));
    } */

  retornarAnimais(count = 10, offset = 0, opcoes_filtro = {
    animais_especificos: '', eras: '', grupos: '', lotes: [], racas: [], sexo: '', nascidos_entre: {
      de: '', ate: ''
    }, incluir_animais_externos: 1
  }, filtraComSexo = 0, agruparComTabelas = 1) {
    return this.http.post(`${environment.api_url}/animais/obterTodosAnimais`, formurlencoded({ filtraComSexo, offset, count, agruparComTabelas, opcoes_filtro }), { headers: this.header });
  }

  retornarAnimaisPorNome(nome, ignore = '', sexo = '', filtraComSexo = 0) {
    return this.http.post(`${environment.api_url}/animais/obterAnimaisPorNome`, formurlencoded({ sexo, ignore, nome, filtraComSexo }), { headers: this.header });
  }

  retornarPesagens(count = 10, offset = 0, opcoes_filtro = {
    animais_especificos: '', eras: '', grupos: '', lotes: [], racas: [], sexo: '', pesagens_feitas_entre: {
      de: '', ate: ''
    }, nascidos_entre: { de: '', ate: '' }, incluir_animais_externos: 1
  }) {
    return this.http.post(`${environment.api_url}/pesagens/obterPesagens`, formurlencoded({ offset, count, opcoes_filtro }), { headers: this.header });
  }

  retornarLotes() {
    return this.http.post(`${environment.api_url}/lotes/obterLotes`, formurlencoded({ offset: 0, count: 99999 }), { headers: this.header });
  }

  retornarRacas() {
    return this.http.post(`${environment.api_url}/racas/obterRacas`, formurlencoded({ offset: 0, count: 99999 }), { headers: this.header });
  }

  retornarEras(sexo: String) {
    return this.http.post(`${environment.api_url}/eras/obterEras`, formurlencoded({ offset: 0, count: 99999, sexo }), { headers: this.header });
  }

  retornarPessoas() {
    return this.http.post(`${environment.api_url}/pessoas/obterPessoas`, formurlencoded({ offset: 0, count: 99999 }), { headers: this.header });
  }

  retornarGrausDeSangue() {
    return this.http.post(`${environment.api_url}/grausSangue/obterGrausSangue`, formurlencoded({ offset: 0, count: 99999 }), { headers: this.header });
  }

  retornarPelagens() {
    return this.http.post(`${environment.api_url}/pelagens/obterPelagens`, formurlencoded({ offset: 0, count: 99999 }), { headers: this.header });
  }

  retornarOrigens() {
    return this.http.post(`${environment.api_url}/origens/obterOrigens`, formurlencoded({ offset: 0, count: 99999 }), { headers: this.header });
  }

  retornarDestinos() {
    return this.http.post(`${environment.api_url}/destinos/obterDestinos`, formurlencoded({ offset: 0, count: 99999 }), { headers: this.header });
  }

  retornarRacoes() {
    return this.http.post(`${environment.api_url}/racoes/obterRacoes`, formurlencoded({ offset: 0, count: 99999, deveConsultarNutrientes: 1 }), { headers: this.header });
  }

  retornarGrupos() {
    return this.http.post(`${environment.api_url}/gruposAnimais/obterGrupos`, formurlencoded({ offset: 0, count: 99999, deveConsultarNutrientes: 1 }), { headers: this.header });
  }

  retornarTiposPesos() {
    return this.http.post(`${environment.api_url}/tiposPesos/obterTiposPesos`, formurlencoded({ offset: 0, count: 99999 }), { headers: this.header });

  }

  cadastrarAnimal(dados: any) {
    return this.http.post(`${environment.api_url}/animais/cadastrarAnimal`, formurlencoded({ ...dados }), { headers: this.header });
  }

  editarAnimal(id: Number, dados: any) {
    return this.http.post(`${environment.api_url}/animais/editarAnimal`, formurlencoded({ id, ...dados }), { headers: this.header });
  }

  deletarAnimais(id: Number) {
    return this.http.post(`${environment.api_url}/animais/excluirAnimal`, formurlencoded({ id }), { headers: this.header });
  }

  cadastrarPesagem(dados: any) {
    return this.http.post(`${environment.api_url}/pesagens/cadastrarPesagem`, formurlencoded({ ...dados }), { headers: this.header });
  }

  deletarPesagens(id: Number) {
    return this.http.post(`${environment.api_url}/pesagens/excluirPesagem`, formurlencoded({ id }), { headers: this.header });
  }

  editarPesagem(id: Number, dados: any) {
    return this.http.post(`${environment.api_url}/pesagens/editarPesagem`, formurlencoded({ id, ...dados }), { headers: this.header });
  }




  checarAutenticacao(token) {
    return this.http.post(`${environment.api_url}/login/checarSessao`, `token=${token}`, { withCredentials: true, headers: { "Content-Type": "application/x-www-form-urlencoded" } }).pipe(map(response => response/* .json() */));
  }


  login(loginInfo) {
    return this.http.post(`${environment.api_url}/login/VerificarUsuarioSenhaJSON`, `usuario=${loginInfo.usuario}&senha=${loginInfo.senha}`, { withCredentials: true, headers: { "Content-Type": "application/x-www-form-urlencoded" } }).pipe(map(response => response/* .json() */));
  }

}
