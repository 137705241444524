import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";

@Injectable()
export class AuthService {
	constructor(
		private apiService: ApiService
	) {}

	public isAuthenticated(): any {
		return this.apiService
            .checarAutenticacao(sessionStorage.getItem("userdata"))
            .toPromise()
			.then((response: any) => {
				if (response.success == 1) {
					return true;
				} else {
					return false;
				}
			});
	}
}
