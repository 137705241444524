import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ApiService } from "../services/api.service";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InserirAnimalComponent } from "../inserir-animal/inserir-animal.component";
import { InserirPesagemComponent } from "../inserir-pesagem/inserir-pesagem.component";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-tabela',
  templateUrl: './tabela.component.html',
  styleUrls: ['./tabela.component.scss']
})
export class TabelaComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator;
  @Input() colunasVisiveis: string[];
  @Input() metodos: string;

  public dataSource: any;
  public quantidadeResultados: string;
  public formFiltro: FormGroup;
  public tiposFiltros: string[] = ['Animal', 'Sexo', 'Lote', 'Raça'];

  public itensFiltrados: any;
  public itemSelecionado: any;


  public filtrosAnimais: any = { animais_especificos: '', eras: '', grupos: '', lotes: [], racas: [], sexo: '', nascidos_entre: { de: '', ate: '' }, incluir_animais_externos: 1 }
  public filtrosPesagens: any = { animais_especificos: '', eras: '', grupos: '', lotes: [], racas: [], sexo: '', pesagens_feitas_entre: { de: '', ate: '' }, nascidos_entre: { de: '', ate: '' }, incluir_animais_externos: 1 }


  public confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private toastr: ToastrService,
    private dialog: MatDialog,
    private apiService: ApiService,
    private formBuilder: FormBuilder,

  ) {

    this.formFiltro = this.formBuilder.group({
      filtro: ['', Validators.required],
      tipoFiltro: ['', Validators.required],
    })

  }

  ngOnInit() {
    this.preencherTabela()
  }


  preencherTabela() {
    this.apiService[`retornar${this.metodos}`]().subscribe((resposta: any) => {
      if (this.metodos == "Animais") {
        this.dataSource = resposta.animais;
        this.quantidadeResultados = resposta.total
      }
      if (this.metodos == "Pesagens") {
        this.dataSource = resposta.pesagens;
        this.quantidadeResultados = resposta.total
      }

    })
  }


  adicionar() {
    this.dialog.open(this.abrirDialog(this.metodos), {
    }).afterClosed().subscribe((resposta: any) => {
      if (resposta !== undefined) {
        if (resposta.success) {
          this.toastr.success('OK!', 'Inserido com sucesso');
        } else {
          this.toastr.error('ERROR', resposta.error)
        }
      }

      this.preencherTabela();
    });
  }

  filtrar() {
    if (this.formFiltro.valid) {

      if (this.metodos == "Animais") {
        switch (this.formFiltro.value.tipoFiltro) {
          case 'animal':
            this.filtrosAnimais.animais_especificos = this.itemSelecionado
            break;
          case 'sexo':
            this.filtrosAnimais.sexo = this.formFiltro.value.filtro
            break;
          case 'lote':
            this.filtrosAnimais.lotes = [this.formFiltro.value.filtro]
            break;
          case 'raça':
            this.filtrosAnimais.racas = [this.formFiltro.value.filtro]
            break;
          default:
            break;
        }

        this.apiService[`retornar${this.metodos}`](10, 0, this.filtrosAnimais).subscribe((resposta: any) => {
          this.dataSource = resposta.animais;
          this.quantidadeResultados = resposta.total
          this.paginator.pageIndex = 0;
        })
      } else {
        switch (this.formFiltro.value.tipoFiltro) {
          case 'animal':
            this.filtrosPesagens.animais_especificos = this.itemSelecionado
            break;
          case 'sexo':
            this.filtrosPesagens.sexo = this.formFiltro.value.filtro
            break;
          case 'lote':
            this.filtrosPesagens.lotes = [this.formFiltro.value.filtro]
            break;
          case 'raça':
            this.filtrosPesagens.racas = [this.formFiltro.value.filtro]
            break;
          default:
            break;
        }

        this.apiService[`retornar${this.metodos}`](10, 0, this.filtrosPesagens).subscribe((resposta: any) => {
          this.dataSource = resposta.pesagens;
          this.quantidadeResultados = resposta.total
          this.paginator.pageIndex = 0;
        })
      }
    }
  }

  buscarFiltro(value: any) {
    switch (this.formFiltro.value.tipoFiltro) {
      case 'animal':
        this.apiService.retornarAnimaisPorNome(value).subscribe((response: any) => {
          this.itensFiltrados = response.animais
        })
        break;
    }
  }

  mudarFiltro() {
    switch (this.formFiltro.value.tipoFiltro) {
      case 'lote':
        this.apiService.retornarLotes().subscribe((response: any) => {
          this.itensFiltrados = response.dados
        })
        break;
      case 'raça':
        this.apiService.retornarRacas().subscribe((response: any) => {
          this.itensFiltrados = response.dados
        })
        break;
      default:
        break;
    }
  }

  setFiltro(value: any) {
    this.itemSelecionado = value.numero
  }



  abrirDialog(tipoTabela: string): any {
    switch (tipoTabela) {
      case 'Animais':
        return InserirAnimalComponent;
      case 'Pesagens':
        return InserirPesagemComponent;
    }
  }


  paginacao(evento) {
    const filtros = this.metodos == "Animais" ? this.filtrosAnimais : this.filtrosPesagens

    this.apiService[`retornar${this.metodos}`](10, evento.pageSize * evento.pageIndex, filtros).subscribe((resposta: any) => {
      if (this.metodos == "Animais") {
        this.dataSource = resposta.animais;
        this.quantidadeResultados = resposta.total
      }
      if (this.metodos == "Pesagens") {
        this.dataSource = resposta.pesagens;
        this.quantidadeResultados = resposta.total
      }
    })
  }

  visualizar(row: any) {
    this.dialog.open(this.abrirDialog(this.metodos), {
      data: row
    }).afterClosed().subscribe((resposta: any) => {
      if (resposta !== undefined) {
        if (resposta.success) {
          this.toastr.success('OK!', 'Inserido com sucesso');
        } else {
          this.toastr.error('ERROR', resposta.error)
        }
      }

      this.preencherTabela();
    });
  }


  deletar(row: any) {
    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: false
    });

    this.confirmDialogRef.componentInstance.confirmMessage = 'Deseja excluir o registro?';

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.apiService[`deletar${this.metodos}`](row.id).subscribe((response: any) => {
          if (response.success) {
            this.toastr.success("OK!", 'Removido com sucesso!')

          } else {
            this.toastr.error("ERROR", 'Não foi possivel remover o registro')

          }
          this.preencherTabela();

        })
      } else {
      }
      this.confirmDialogRef = null;
    });

  }

}
