import { Component, OnInit, ViewChild, Inject, Optional } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from "../services/api.service";

import * as moment from 'moment';

@Component({
  selector: 'app-inserir-pesagem',
  templateUrl: './inserir-pesagem.component.html',
  styleUrls: ['./inserir-pesagem.component.scss']
})
export class InserirPesagemComponent implements OnInit {

  public form: FormGroup;


  public animais: any;
  public tipospesos: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dadosInput,
    private formBuilder: FormBuilder,
    private apiService: ApiService
  ) {

    if (this.dadosInput !== null) {
      const d = this.dadosInput.data_pesagem.split('/')

      this.form = this.formBuilder.group({
        data_pesagem: [`${d[2]}${'-'}${d[1]}${'-'}${d[0]}`, Validators.required],
        id_animal: [this.dadosInput.id_animal, Validators.required],
        peso: [this.dadosInput.peso, Validators.required],
        id_tipo_peso: [this.dadosInput.id_tipo_peso, Validators.required]
      })
    } else {
      this.form = this.formBuilder.group({
        data_pesagem: ['', Validators.required],
        id_animal: ['', Validators.required],
        peso: ['', Validators.required],
        id_tipo_peso: ['', Validators.required]
      })
    }

  }

  ngOnInit() {

    this.apiService.retornarAnimais().subscribe((response: any) => {
      this.animais = response.animais
    })

    this.apiService.retornarTiposPesos().subscribe((response: any) => {
      this.tipospesos = response.dados
    })
  }

  cadastrarPesagem() {
    if (this.form.valid) {
      if (this.dadosInput !== null) {
        this.apiService.editarPesagem(this.dadosInput.id ,this.form.value).subscribe((response: any) => {
          console.log(response)
        })
      } else {
        this.form.value.data_pesagem = moment(this.form.value.data_pesagem).format('YYYY-MM-DD')

        this.apiService.cadastrarPesagem(this.form.value).subscribe((response: any) => {
          console.log(response)
        })
      }
    }




  }

}
