import { Component, OnInit, ViewChild, Inject, Optional } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from "../services/api.service";

import * as moment from 'moment';



@Component({
  selector: 'app-inserir-animal',
  templateUrl: './inserir-animal.component.html',
  styleUrls: ['./inserir-animal.component.scss']
})
export class InserirAnimalComponent implements OnInit {

  public form: FormGroup;



  public situacoesEras: any;
  public lotes: any;
  public pessoas: any;
  public grausdesangue: any;
  public pelagens: any;
  public destinos: any;
  public racoes: any;
  public grupos: any;
  public animais: any;
  public racas: any;
  public origens: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dadosInput,
    private formBuilder: FormBuilder,
    private apiService: ApiService

  ) {
    if (this.dadosInput !== null) {
      this.form = this.formBuilder.group({
        numero: [this.dadosInput.numero, Validators.required],
        nome: [this.dadosInput.nome, Validators.required],
        nascimento: [this.dadosInput.nascimento, Validators.required],
        sexo: [this.dadosInput.sexo, Validators.required],
        id_raca: [this.dadosInput.id_raca, Validators.required],
        data_entrada: [this.dadosInput.data_entrada, Validators.required],
        id_era: [this.dadosInput.id_era, Validators.required],
        data_desmama: [this.dadosInput.data_desmama, Validators.required],
        previsao_abate: [this.dadosInput.previsao_abate, Validators.required],
        id_lote: this.dadosInput.id_lote,
        id_criador: this.dadosInput.id_criador,
        id_grau_de_sangue: this.dadosInput.id_grau_de_sangue,
        brinco_marca: this.dadosInput.brinco_marca,
        id_pelagem: this.dadosInput.id_pelagem,
        id_origem: this.dadosInput.id_origem,
        id_destino: this.dadosInput.id_destino,
        id_racao: this.dadosInput.id_racao,
        id_grupo: this.dadosInput.id_grupo,
        valor: this.dadosInput.valor,
        unidade_animal: this.dadosInput.unidade_animal,
        pertence_ao_rebanho: this.dadosInput.pertence_ao_rebanho,
        id_pai: this.dadosInput.id_pai,
        id_mae: this.dadosInput.id_mae,
        id_mae_aluguel: this.dadosInput.id_mae_aluguel,
        observacoes: this.dadosInput.observacoes,
      })
    } else {
      this.form = this.formBuilder.group({
        numero: ['', Validators.required],
        nome: ['', Validators.required],
        nascimento: ['', Validators.required],
        sexo: ['', Validators.required],
        id_raca: ['', Validators.required],
        data_entrada: ['', Validators.required],
        id_era: ['', Validators.required],
        data_desmama: ['', Validators.required],
        previsao_abate: ['', Validators.required],
        id_lote: '',
        id_criador: '',
        id_grau_de_sangue: '',
        brinco_marca: '',
        id_pelagem: '',
        id_origem: '',
        id_destino: '',
        id_racao: '',
        id_grupo: '',
        valor: '',
        unidade_animal: '',
        pertence_ao_rebanho: '',
        id_pai: '',
        id_mae: '',
        id_mae_aluguel: '',
        observacoes: '',
      })
    }

  }

  ngOnInit() {
    this.apiService.retornarLotes().subscribe((response: any) => {
      this.lotes = response.dados
    })

    this.apiService.retornarPessoas().subscribe((response: any) => {
      this.pessoas = response.pessoas
    })

    this.apiService.retornarGrausDeSangue().subscribe((response: any) => {
      this.grausdesangue = response.dados
    })

    this.apiService.retornarPelagens().subscribe((response: any) => {
      this.pelagens = response.dados
    })

    this.apiService.retornarDestinos().subscribe((response: any) => {
      this.destinos = response.dados
    })

    this.apiService.retornarRacoes().subscribe((response: any) => {
      this.racoes = response.racoes
    })

    this.apiService.retornarGrupos().subscribe((response: any) => {
      this.grupos = response.dados
    })

    this.apiService.retornarAnimais().subscribe((response: any) => {
      this.animais = response.animais
    })

    this.apiService.retornarRacas().subscribe((response: any) => {
      this.racas = response.dados
    })

    this.apiService.retornarOrigens().subscribe((response: any) => {
      this.origens = response.dados
    })
  }

  buscarEras(sexo: String) {
    this.apiService.retornarEras(sexo).subscribe((response: any) => {
      this.situacoesEras = response.eras
    })
  }


  cadastrarAnimal() {
    if (this.form.valid) {
      if (this.dadosInput !== null) {
        this.apiService.editarAnimal(this.dadosInput.id ,this.form.value).subscribe((response : any) => {
          console.log(response)
        })
      } else {
        this.form.value.nascimento = moment(this.form.value.nascimento).format('YYYY-MM-DD')
        this.form.value.data_entrada = moment(this.form.value.data_entrada).format('YYYY-MM-DD')
        this.form.value.data_desmama = moment(this.form.value.data_desmama).format('YYYY-MM-DD')
        this.form.value.previsao_abate = moment(this.form.value.previsao_abate).format('YYYY-MM-DD')

        this.apiService.cadastrarAnimal(this.form.value).subscribe((response: any) => {
          console.log(response)
        })
      }


    }
  }


}
