
import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { AuthGuardService } from "../services/auth-guard.service";

import { Router } from "@angular/router";
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

	isLoggedIn$: Observable<boolean>;

	constructor(private router: Router, private authService : AuthGuardService) {

    }

	ngOnInit() {
        this.isLoggedIn$ = this.authService.isLoggedIn
    }

	logout() {
        this.authService.logout()
	}
}
